exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-bemanningsbolag-tsx": () => import("./../../../src/pages/bemanningsbolag.tsx" /* webpackChunkName: "component---src-pages-bemanningsbolag-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-companies-doctors-tsx": () => import("./../../../src/pages/companies/doctors.tsx" /* webpackChunkName: "component---src-pages-companies-doctors-tsx" */),
  "component---src-pages-companies-index-tsx": () => import("./../../../src/pages/companies/index.tsx" /* webpackChunkName: "component---src-pages-companies-index-tsx" */),
  "component---src-pages-companies-nurses-tsx": () => import("./../../../src/pages/companies/nurses.tsx" /* webpackChunkName: "component---src-pages-companies-nurses-tsx" */),
  "component---src-pages-disablega-tsx": () => import("./../../../src/pages/disablega.tsx" /* webpackChunkName: "component---src-pages-disablega-tsx" */),
  "component---src-pages-doctors-index-tsx": () => import("./../../../src/pages/doctors/index.tsx" /* webpackChunkName: "component---src-pages-doctors-index-tsx" */),
  "component---src-pages-dropptakt-tsx": () => import("./../../../src/pages/dropptakt.tsx" /* webpackChunkName: "component---src-pages-dropptakt-tsx" */),
  "component---src-pages-email-sign-in-oauth-ts": () => import("./../../../src/pages/emailSignInOauth.ts" /* webpackChunkName: "component---src-pages-email-sign-in-oauth-ts" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-jobb-tsx": () => import("./../../../src/pages/jobb.tsx" /* webpackChunkName: "component---src-pages-jobb-tsx" */),
  "component---src-pages-jobs-[id]-tsx": () => import("./../../../src/pages/jobs/[id].tsx" /* webpackChunkName: "component---src-pages-jobs-[id]-tsx" */),
  "component---src-pages-jobs-tsx": () => import("./../../../src/pages/jobs.tsx" /* webpackChunkName: "component---src-pages-jobs-tsx" */),
  "component---src-pages-lediga-jobb-tsx": () => import("./../../../src/pages/lediga-jobb.tsx" /* webpackChunkName: "component---src-pages-lediga-jobb-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-logout-tsx": () => import("./../../../src/pages/logout.tsx" /* webpackChunkName: "component---src-pages-logout-tsx" */),
  "component---src-pages-lonekalkylator-tsx": () => import("./../../../src/pages/lonekalkylator.tsx" /* webpackChunkName: "component---src-pages-lonekalkylator-tsx" */),
  "component---src-pages-lonerapportering-index-tsx": () => import("./../../../src/pages/lonerapportering/index.tsx" /* webpackChunkName: "component---src-pages-lonerapportering-index-tsx" */),
  "component---src-pages-lonerapportering-tack-tsx": () => import("./../../../src/pages/lonerapportering/tack.tsx" /* webpackChunkName: "component---src-pages-lonerapportering-tack-tsx" */),
  "component---src-pages-lonestatistik-tsx": () => import("./../../../src/pages/lonestatistik.tsx" /* webpackChunkName: "component---src-pages-lonestatistik-tsx" */),
  "component---src-pages-norge-tsx": () => import("./../../../src/pages/norge.tsx" /* webpackChunkName: "component---src-pages-norge-tsx" */),
  "component---src-pages-nurses-index-tsx": () => import("./../../../src/pages/nurses/index.tsx" /* webpackChunkName: "component---src-pages-nurses-index-tsx" */),
  "component---src-pages-p-tsx": () => import("./../../../src/pages/p/[...].tsx" /* webpackChunkName: "component---src-pages-p-tsx" */),
  "component---src-pages-ref-tsx": () => import("./../../../src/pages/ref.tsx" /* webpackChunkName: "component---src-pages-ref-tsx" */),
  "component---src-pages-register-tsx": () => import("./../../../src/pages/register.tsx" /* webpackChunkName: "component---src-pages-register-tsx" */),
  "component---src-pages-sorry-to-see-you-go-tsx": () => import("./../../../src/pages/sorry-to-see-you-go.tsx" /* webpackChunkName: "component---src-pages-sorry-to-see-you-go-tsx" */),
  "component---src-pages-upphandlingar-tsx": () => import("./../../../src/pages/upphandlingar.tsx" /* webpackChunkName: "component---src-pages-upphandlingar-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/BlogPost.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-region-salary-tsx": () => import("./../../../src/templates/RegionSalary.tsx" /* webpackChunkName: "component---src-templates-region-salary-tsx" */),
  "component---src-templates-region-specific-tsx": () => import("./../../../src/templates/RegionSpecific.tsx" /* webpackChunkName: "component---src-templates-region-specific-tsx" */),
  "component---src-templates-subpage-tsx": () => import("./../../../src/templates/Subpage.tsx" /* webpackChunkName: "component---src-templates-subpage-tsx" */)
}

